import process from 'process';
import { stringify } from 'qs';

enum BillingTypes {
  YEAR = 'year',
  MONTH = 'month',
}

enum SubscriptionTypes {
  INDIVIDUAL = 'individual',
  BUSINESS = 'business',
}

const INDIVIDUAL_PRICE_MONTHLY: number = 3.99;
const INDIVIDUAL_PRICE_ANNUAL: number = 3.49;

const BUSINESS_PRICE_MONTHLY: number = 4.99;
const BUSINESS_PRICE_ANNUAL: number = 4.49;

const MONTHLY_PERIOD_TEXT: string = '/Mon';
const ANNUAL_PERIOD_TEXT: string = '/Mon';

const INDIVIDUAL_DISCOUNT: string = `${Math.floor(100 - (INDIVIDUAL_PRICE_ANNUAL * 100) / INDIVIDUAL_PRICE_MONTHLY)}%`;
const BUSINESS_DISCOUNT: string = `${Math.floor(100 - (BUSINESS_PRICE_ANNUAL * 100) / BUSINESS_PRICE_MONTHLY)}%`;
const DISCOUNT_TEXT: string = 'Save';

const signUpUrl = (): string => {
  return `https://app${process.env.ENV === 'prod' ? '' : `-${process.env.ENV}`}.${process.env.DOMAIN_NAME}/auth/sign-up`;
};

const hex = (value: string): string => {
  return [...value].reduce((acc: string, curr: string) => acc.concat(('000' + curr.charCodeAt(0).toString(16)).slice(-4)), '');
};

window.addEventListener('load', (): void => {
  const monthly: Element = document.getElementById('monthly');
  const annual: Element = document.getElementById('annual');

  const individualPrice: HTMLElement = document.getElementById('individual-price');
  const individualPeriod: HTMLElement = document.getElementById('individual-period');
  const individualDiscount: HTMLElement = document.getElementById('individual-discount');
  const individualSelector: HTMLElement = document.getElementById('individual-selector');

  const businessPrice: HTMLElement = document.getElementById('business-price');
  const businessPeriod: HTMLElement = document.getElementById('business-period');
  const businessDiscount: HTMLElement = document.getElementById('business-discount');
  const businessSelector: HTMLElement = document.getElementById('business-selector');

  individualDiscount.innerText = `${DISCOUNT_TEXT} ${INDIVIDUAL_DISCOUNT}`;
  businessDiscount.innerText = `${DISCOUNT_TEXT} ${BUSINESS_DISCOUNT}`;

  const makeMonthlyPriceActive = (): void => {
    monthly.classList.add('active');
    annual.classList.remove('active');

    individualPrice.innerText = INDIVIDUAL_PRICE_MONTHLY.toString();
    individualPeriod.innerText = MONTHLY_PERIOD_TEXT;
    individualDiscount.classList.add('h-0', 'py-0');
    individualDiscount.classList.remove('h-[40px]', 'py-2');

    businessPrice.innerText = BUSINESS_PRICE_MONTHLY.toString();
    businessPeriod.innerText = MONTHLY_PERIOD_TEXT;
    businessDiscount.classList.add('h-0', 'py-0');
    businessDiscount.classList.remove('h-[40px]', 'py-2');
  };

  const makeAnnualPriceActive = (): void => {
    annual.classList.add('active');
    monthly.classList.remove('active');

    individualPrice.innerText = INDIVIDUAL_PRICE_ANNUAL.toString();
    individualPeriod.innerText = ANNUAL_PERIOD_TEXT;
    individualDiscount.classList.remove('h-0', 'py-0');
    individualDiscount.classList.add('h-[40px]', 'py-2');

    businessPrice.innerText = BUSINESS_PRICE_ANNUAL.toString();
    businessPeriod.innerText = ANNUAL_PERIOD_TEXT;
    businessDiscount.classList.remove('h-0', 'py-0');
    businessDiscount.classList.add('h-[40px]', 'py-2');
  };

  const redirectToSignUp = (event: PointerEvent): void => {
    const query: string = stringify({
      bt: hex(monthly.classList.contains('active') ? BillingTypes.MONTH : BillingTypes.YEAR),
      st: hex(individualSelector.isEqualNode(event.target as HTMLElement) ? SubscriptionTypes.INDIVIDUAL : SubscriptionTypes.BUSINESS),
    });

    (window.location.href = `${signUpUrl()}?${query}`), '_blank';
  };

  if (!monthly.classList.contains('active') && !annual.classList.contains('active')) {
    makeMonthlyPriceActive();
  }

  monthly.addEventListener('click', makeMonthlyPriceActive);
  annual.addEventListener('click', makeAnnualPriceActive);

  individualSelector.addEventListener('click', redirectToSignUp);
  businessSelector.addEventListener('click', redirectToSignUp);
});
